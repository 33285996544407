@tailwind base;
@tailwind components;
@tailwind utilities;

@import "rsuite/dist/rsuite.css";

html, body, #root {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rs-pagination-btn{
  color: azure;
}
.rs-pagination-btn.rs-pagination-btn-active{
  color: azure;
  border-color: #a4f504 !important;
}
.rs-pagination-group-skip{
  color: azure;
}
.rs-picker-popup{
  width: 180px;
  background-color: grey;
}
.rs-checkbox-label, .rs-picker-value-item{
  color: white;
}

.rs-picker-toggle{
  border-radius: 0px;
}

.rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle{
  background-color: grey !important;
  border: 0px;
  color: white !important
}
.rs-picker-toggle .rs-picker-toggle-placeholder,.rs-picker-toggle-value{
  color: white !important;
}

.rs-picker-toggle-indicator .rs-picker-caret-icon,.rs-picker-select-menu-item{
  color: white !important;
}
.rs-loader-spin{
  top:-5px
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active{
  background-color: #737271 !important;
}
.rs-table-cell{
  border-right: 1px solid #333231 !important ;
  border-bottom: 1px solid #333231 !important ;
}
.rs-table-cell-header .rs-table-cell{
  border-right: 1px solid #333231 !important ;
  border-bottom: 1px solid #a4f504 !important ;
}
.rs-table-cell-header .rs-table-cell  .rs-table-cell-content{
  border-right: 1px solid #333231 !important ;
  border-bottom: 2px solid #a4f504 !important ;
}
/* .rs-table-cell{
  border:1px solid #333231
} */
.rs-picker-clean {
  color: white;
}

table {
  border: 1px solid black;
  border-collapse: separate;
  border-spacing: 0;
}
table td {
  border: 1px solid #323331;
}
table th {
  border: 1px solid #323331;
}

table tr td {
  border-right: 0;
}
table tr:last-child td {
  border-bottom: 0;
}
table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}
table tr td{
  border-top: 0;
} 

.rs-table-column-group-cell .rs-table-cell{
  background-color: black !important;
}

.rs-modal-wrapper{
  display: flex;
  align-items: center;
}

.rs-modal-content {
  background-color: black;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #575757;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}